export const numberItemsStart = (items) => (
  items.data && items.data.length
    ? items.page * items.perPage - items.perPage + 1
    : 0
)

export const numberItemsEnd = (items) => {
  let end = items.total
  if (items.perPage < items.total && items.perPage * items.page < items.total) {
    end = items.perPage * items.page
  }
  return end
}

export const englishName = (items, field = 'name') => (
  items.map((item) => {
    item.correctedName = item[field].en
    return item
  })
)
